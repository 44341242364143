import React from "react"
import { Link, graphql } from "gatsby"
import "../components/blog-post.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Picture from "../images/blog-post.jpg"

export default ({ data }) => {
  let post = null
  let prevSlug = null
  let nextSlug = null
  data.allWordpressPost.edges.map(({ node, previous, next }) => {
    if (node.slug === data.wordpressPost.slug) {
      post = node
      nextSlug = previous != null ? previous.slug : ""
      prevSlug = next != null ? next.slug : ""
    }
    return null
  })

  return (
    <Layout>
      <SEO
        title={post.title}
        description={
          post.content.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 155) + "..."
        }
        image={
          post.featured_media === null
            ? Picture
            : post.featured_media.source_url
        }
      />
      <Header />
      <section className="container slice slice-lg">
        <div className="card mt--300 border-0 shadow-lg zindex-100">
          <div className="card-body text-center">
            <img
              src={
                post.featured_media === null
                  ? Picture
                  : post.featured_media.source_url
              }
              alt={"Ajankohtaista"}
              className={"fixed-width"}
            />
            <h1
              className="lh-150 mt-5 mb-4"
              dangerouslySetInnerHTML={{ __html: post.title }}
            />
            <h5 className="text-muted mb-4">Kirjoittaja: {post.author.name}</h5>
          </div>
        </div>
      </section>
      <section className="container slice slice-lg">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <p>Julkaistu: {post.date}</p>
      </section>
      <section className="container slice slice-lg">
        <hr />
        <div className="row d-flex justify-content-between">
          <div className={"col-6 col-md-4"}>
            <Link
              to={"/" + prevSlug}
              className="btn btn-primary rounded-pill hover-scale-110"
            >
              Edellinen
            </Link>
          </div>
          <div
            className={
              "col-6 col-md-4 text-right " + (nextSlug === "" ? "d-none" : "")
            }
          >
            <Link
              to={"/" + nextSlug}
              className="btn btn-primary rounded-pill hover-scale-110"
            >
              Seuraava
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      slug
    }
    allWordpressPost {
      edges {
        node {
          id
          date(formatString: "D.M.YYYY")
          title
          content
          slug
          author {
            name
          }
          featured_media {
            source_url
          }
        }
        previous {
          slug
        }
        next {
          slug
        }
      }
    }
  }
`
